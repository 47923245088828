import { AuthHelper } from '@onestepprojects/authentication-helper'
import Fund from '@onestepprojects/fund-module'
import { FC, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom-v5'

import LoggedOutLayout from '../../layouts/LoggedOut'
import Login from '../../views/Login'
import NotFound from '../../views/NotFound'

interface AnonymousProps {
  authHelper: typeof AuthHelper
}

const Anonymous: FC<AnonymousProps> = ({ authHelper }) => {
  // TODO: Should add a Container component to the fund module, then remove the css loading
  useEffect(() => {
    require('@onestepprojects/fund-module/dist/index.css')
  }, [])

  return (
    <Router basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        <Route path='/login' component={Login} />
        <Route
          path='/'
          component={(props: any) => {
            return (
              <LoggedOutLayout {...props} authHelper={authHelper.current}>
                <Fund {...props} authHelper={authHelper.current} mountPath='/' />
              </LoggedOutLayout>
            )
          }}
        />
        <Route path='*' component={NotFound} />
      </Switch>
    </Router>
  )
}

export default Anonymous
