import { AuthHelper } from '@onestepprojects/authentication-helper'
import { FC, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom-v5'

import { ApplicationContext } from '../../contexts/ApplicationContext'

import Organization from '@onestepprojects/organization-module'
import { DefaultLayout } from '../../layouts'

import { User } from '../../utils/getUser'

interface Auth {
  token: string | undefined
  person: null
  roles: string[]
}

interface NoPersonProps {
  user: null | User
  person: null
  isAdmin: boolean
  authHelper: typeof AuthHelper
  auth: Auth
  uniqueId: number
}

const NoPerson: FC<NoPersonProps> = ({ user, person, isAdmin, authHelper, auth, uniqueId }) => {
  useEffect(() => {
    require('@onestepprojects/organization-module/dist/index.css')
  })

  return (
    <Router basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        <Route
          path='/organizations'
          component={(props: any) => {
            return (
              <ApplicationContext.Provider value={{ match: props.match, person, user, isAdmin }}>
                <DefaultLayout {...props} person={person} user={user} isAdmin={isAdmin}>
                  <Organization
                    {...props}
                    auth={auth}
                    person={person}
                    authHelper={authHelper.current}
                    uniqueId={uniqueId}
                    mountPath={'/organizations'}
                  />
                </DefaultLayout>
              </ApplicationContext.Provider>
            )
          }}
        />
      </Switch>
    </Router>
  )
}

export default NoPerson
