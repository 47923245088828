import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import styled from 'styled-components'
import MainFooter from '../components/layout/MainFooter'
import MainNavbar from '../components/layout/MainNavbar/MainNavbar'
import MainSidebar from '../components/layout/MainSidebar/MainSidebar'
import ErrorBoundary from '../ErrorBoundary'

interface DefaultLayoutProps {
  children: React.ReactNode
  noNavbar: boolean
  noFooter: boolean
}

const MainContent = styled(Col).attrs({
  component: 'main',
})`
  padding: 0 !important;
`

const ContentContainer = styled(Row)`
  min-height: 100vh;
`

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  noNavbar,
  noFooter,
}: DefaultLayoutProps) => {
  return (
    <Container fluid>
      <ContentContainer>
        <MainSidebar />
        <MainContent>
          {!noNavbar && <MainNavbar />}
          <ErrorBoundary>{children}</ErrorBoundary>
          {!noFooter && <MainFooter />}
        </MainContent>
      </ContentContainer>
    </Container>
  )
}

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
}

export default DefaultLayout
