import { CognitoUser } from 'amazon-cognito-identity-js'
import Amplify, { Auth } from 'aws-amplify'
import amplifyConfig from '../data/aws-amplify.config'

Amplify.configure(amplifyConfig)

export type User = CognitoUser

export default function getUser(): Promise<User> {
  return Auth.currentAuthenticatedUser()
}
