import { Hub } from 'aws-amplify'
import 'chart.js' // TODO: Is it be possible to remove?
import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom-v5'

import { AuthHelper } from '@onestepprojects/authentication-helper'

import Anonymous from './components/home/Anonymous'
import LoggedIn from './components/home/LoggedIn'
import NoPerson from './components/home/NoPerson'

import routes from './routes'
import getUser, { User } from './utils/getUser'

const App = () => {
  const history = useHistory()

  const [user, setUser] = useState<User | null>(null)
  const [roles, setRoles] = useState<string[]>([])
  const [person, setPerson] = useState(null)
  const [uniqueId, setUniqueId] = useState(Math.random())
  const [loading, setLoading] = useState(true)

  const token = useMemo(() => user?.getSignInUserSession()?.getAccessToken().getJwtToken(), [user])
  const isAdmin = useMemo(() => roles?.includes('admin'), [roles])
  const auth = useMemo(() => ({ token, person, roles }), [token, person, roles])

  const authHelper = useRef(new AuthHelper())

  useEffect(() => {
    const signIn = async () => {
      try {
        const user = await getUser()
        setUser(user)
      } catch (error) {
        setLoading(false)
      }
    }

    signIn()
  }, [history])

  useEffect(() => {
    if (!user) return

    const getAdmin = async () => {
      const response = await authHelper.current.Get(
        `${process.env.REACT_APP_AUTHORIZATION_API_URL}/user`
      )

      if (response.status === 200) {
        setRoles(response.data.data.roles)
      }
    }

    getAdmin()

    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          setUser(await getUser())
          break
        case 'signOut':
          setUser(null)
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
        default:
          break
      }
    })
  }, [user])

  useEffect(() => {
    if (!user) return

    const getPerson = async () => {
      const response = await authHelper.current.Get(
        process.env.REACT_APP_ORG_MODULE_API_URL + '/persons/requester/token'
      )

      if (response && response.status === 200 && response.data) {
        setPerson(response.data)
      } else {
        history.push('/organizations/registration')
        setUniqueId(Math.random())
      }
    }

    getPerson().then(() => {
      setLoading(false)
    })
  }, [user, history])

  if (loading) return null

  // Logged in
  if (user && person) {
    return (
      <LoggedIn
        user={user}
        routes={routes}
        person={person}
        isAdmin={isAdmin}
        authHelper={authHelper.current}
        auth={auth}
        uniqueId={uniqueId}
      />
    )
  }

  // Logged in but not a staff
  if (user && !person) {
    return (
      <NoPerson
        user={user}
        person={person}
        isAdmin={isAdmin}
        authHelper={authHelper.current}
        auth={auth}
        uniqueId={uniqueId}
      />
    )
  }

  // Not logged in
  return <Anonymous authHelper={authHelper} />
}

export default App
