import React from 'react'

import Rewards from './components/Rewards'
import { DefaultLayout } from './layouts'
import NotFound from './views/NotFound'

/**
 * Lazy load module. Render with react suspense.
 *
 * @see https://webpack.js.org/guides/code-splitting/#dynamic-imports
 */
const lazy = (factory: Parameters<typeof React.lazy>[0]) => {
  const Component = React.lazy(factory)
  return (props: any) => (
    <React.Suspense fallback={null}>
      <Component {...props} />
    </React.Suspense>
  )
}

// Route Views
const Fund = lazy(() => import('@onestepprojects/fund-module'))
const Health = lazy(() => import('@onestepprojects/health-module'))
const Solutions = lazy(() => import('@onestepprojects/solutions-library'))
const Resource = lazy(() => import('@onestepprojects/resource-module'))
const Organization = lazy(() => import('@onestepprojects/organization-module'))
const Survey = lazy(() => import('@onestepprojects/survey-module'))
const Accreditation = lazy(() => import('@onestepprojects/accreditation-module'))
const AccreditationAdmin = lazy(() => import('@onestepprojects/accreditation-admin-module'))
const Ticket = lazy(() => import('@onestepprojects/ticket-module'))

export interface CustomRoute {
  path: string
  exact?: boolean
  admin?: boolean
  layout?: React.ComponentType<any>
  component: React.ComponentType<any>
  css?: () => void
}

const routes: CustomRoute[] = [
  {
    path: '/health',
    exact: false,
    admin: false,
    layout: DefaultLayout,
    component: Health,
    css: () => import('@onestepprojects/health-module/dist/index.css'),
  },
  {
    path: '/solutions',
    exact: false,
    admin: false,
    layout: DefaultLayout,
    component: Solutions,
    css: () => {
      import('@onestepprojects/fund-module/dist/index.css') // Load bootstrap css
      import('@onestepprojects/solutions-library/dist/index.css')
    },
  },
  {
    path: '/resource',
    exact: false,
    admin: false,
    layout: DefaultLayout,
    component: Resource,
    css: () => {
      import('@onestepprojects/fund-module/dist/index.css') // Load bootstrap css
      import('@onestepprojects/resource-module/dist/index.css')
    },
  },
  {
    path: '/accreditation/admin',
    admin: true,
    exact: false,
    layout: DefaultLayout,
    component: AccreditationAdmin,
    css: () => import('@onestepprojects/accreditation-admin-module/dist/index.css'),
  },
  {
    path: '/accreditation',
    admin: false,
    exact: false,
    layout: DefaultLayout,
    component: Accreditation,
    css: () => import('@onestepprojects/accreditation-module/dist/index.css'),
  },
  {
    path: '/survey',
    admin: false,
    exact: false,
    layout: DefaultLayout,
    component: Survey,
    css: () => {
      import('@onestepprojects/fund-module/dist/index.css') // Load bootstrap css
      import('@onestepprojects/survey-module/dist/index.css')
    },
  },
  {
    path: '/organizations',
    admin: false,
    exact: false,
    layout: DefaultLayout,
    component: Organization,
    css: () => import('@onestepprojects/organization-module/dist/index.css'),
  },
  {
    path: '/rewards',
    exact: true,
    admin: false,
    layout: DefaultLayout,
    component: Rewards,
  },
  {
    path: '/tickets',
    admin: false,
    exact: false,
    layout: DefaultLayout,
    component: Ticket,
    css: () => import('@onestepprojects/ticket-module/dist/index.css'),
  },
  {
    path: '/',
    exact: false,
    admin: false,
    component: Fund,
    css: () => import('@onestepprojects/fund-module/dist/index.css'),
  },
  {
    path: '*',
    component: NotFound,
  },
]

export default routes
