import { AuthHelper } from '@onestepprojects/authentication-helper'
import { FC } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom-v5'

import { ApplicationContext } from '../../contexts/ApplicationContext'

import { DefaultLayout } from '../../layouts'
import { CustomRoute } from '../../routes'
import { User } from '../../utils/getUser'

interface Auth {
  token: string | undefined
  person: null
  roles: string[]
}

interface LoggedInProps {
  user: null | User
  routes: CustomRoute[]
  person: null
  isAdmin: boolean
  authHelper: typeof AuthHelper
  auth: Auth
  uniqueId: number
}

const LoggedIn: FC<LoggedInProps> = ({
  user,
  routes,
  person,
  isAdmin,
  authHelper,
  auth,
  uniqueId,
}) => {
  return (
    <Router basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props: any) => {
                if (typeof route.css === 'function') route.css()

                return route.admin && !isAdmin ? (
                  <Redirect to='/' />
                ) : (
                  <ApplicationContext.Provider
                    value={{ match: props.match, person, user, isAdmin }}
                  >
                    <DefaultLayout
                      {...props}
                      person={person}
                      authHelperauthHelper={authHelper.current}
                      isAdmin={isAdmin}
                    >
                      <route.component
                        {...props}
                        auth={auth}
                        person={person}
                        authHelper={authHelper.current}
                        uniqueId={uniqueId}
                        mountPath={route.path}
                      />
                    </DefaultLayout>
                  </ApplicationContext.Provider>
                )
              }}
            />
          )
        })}
      </Switch>
    </Router>
  )
}

export default LoggedIn
