import React, { useContext } from "react";
import styled from "styled-components";
import { ApplicationContext } from "../../../../contexts/ApplicationContext";

// import Notifications from "./Notifications";
import UserActions from "./UserActions";

interface NavbarNavElProps {
  leftBorder: boolean;
}

const NavbarNavEl = styled.ul<NavbarNavElProps>`
  margin-bottom: 0;
  padding-left: 0;
  border-left: ${(props) => (props.leftBorder ? "1px solid #e1e5eb" : "none")};
`;

const NavbarNav: React.FC = () => {
  const { person } = useContext(ApplicationContext);

  const loginable = !["fund.onestepprojects.org"].includes(location.hostname);

  return (
    <NavbarNavEl leftBorder={!!person}>
      {/* <Notifications /> */}
      {/* FIXME: history props is not used in UserActions component */}
      {loginable && <UserActions />}
    </NavbarNavEl>
  );
};

export default NavbarNav;
